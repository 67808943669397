import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
//import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import { getCall, postCall, putCall } from '../../helpers/apiManager';
import { useForm } from "react-hook-form";
import { useAuth } from '../../hooks/useAuth';
import {  useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { CAMPAIGNS_ENDPOINT, POLITICAL_PARTY_ENDPOINT } from '../../routes';
import Dropdown from '../common/Dropdown';
import BasicDatePicker from '../common/BasicDatePicker';
import { PROVINCES_ENDPOINT, 
  CANTONS_ENDPOINT, 
  DISTRICTS_ENDPOINT, 
  COUNTRIES_ENDPOINT
} from '../../routes';
import { useState } from 'react';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import FileUploader from '../common/FileUploader';
import { useTranslation } from 'react-i18next';

const CreateCampaign = ({onSuccess, editData}) =>{
  const { t, i18n } = useTranslation(); 
  const {isLoggedIn, logout} = useAuth();
  const navigate = useNavigate();
  const [provinces, setProvinces] = useState([]);
  const [cantons, setCantons] = useState([]);
  const [districts, setDistricts] = useState([]);
  const [countries, setCountries] = useState([]);
  const [parties, setParties] = useState([]);
  const [image, setImage] = React.useState(null);

  const form = useForm({
    defaultValues: {
      period: "",
      country: "",
      province: editData.province || "",
      canton: "",
      district:"",
      candidate_name:"",
      execution_date: dayjs(),
      initial_date: dayjs(),
      end_date: dayjs(),
      description: "",
      voters_goal:"",
      paid: editData.paid,
      cost:0,
      political_party_id: 0
    },
  })


  const { 
    handleSubmit, 
    register,
    formState,
    watch,
    setValue,
    getValues,
    control
  } = form;

  const {errors} = formState;

  const prepareDropdownData = (values) => {
    const data = values.map((value) => {
      return { "value" : value , "label": value} 
    });
    return data;
  }
   
  useEffect( () => {
    if(!isEmpty(editData)){
     for (let key in editData) {
       setValue(key, editData[key]);
       console.log("Setting: " + key + " => "+ editData[key]);
     }
    }
   }, [editData]);

  useEffect( () => {
    const fetchCountries = async () => {
      const countriesResults = await getCall(COUNTRIES_ENDPOINT);
      const data = countriesResults.countries.map((value) => {
        return { "value" : value.id , "label": value.name} 
      });
      setCountries(data);
    }

    const fetchProvinces = async () => {
      const provincesResults = await getCall(PROVINCES_ENDPOINT);
      const data = prepareDropdownData(provincesResults.provinces);
      setProvinces(data);
    }
    const fetchCantons = async () => {
      const provincesResults = await getCall(CANTONS_ENDPOINT);
      setCantons(prepareDropdownData(provincesResults.cantons));
    }
    const fetchDistricts = async () => {
      const districtsR = await getCall(DISTRICTS_ENDPOINT);
      setDistricts(prepareDropdownData(districtsR.districts));
    }
    const fetchParties = async () =>{
      const partiesR = await getCall(POLITICAL_PARTY_ENDPOINT);
      if(!partiesR.success && partiesR.login_redirect){
        logout();
      }
      const data = partiesR.data.map((value) => {
        return { "value" : value.id , "label": value.party_name} 
      });
      setParties(data);
    }

    setImage(editData['candidate_image']);

    fetchParties();
    fetchProvinces();
    fetchCantons();
    fetchDistricts();
    fetchCountries();
  }, []);

  const onSubmit = async  (data) => { 
    
    let results = null;
    let action = ''

    if(!image){
      alert('Por favor seleccione una foto del candidato');
      return;
    }
      

    data['candidate_image'] = image.base64;
    if(!isEmpty(editData)){
      results = await putCall(`${CAMPAIGNS_ENDPOINT}/${data.id}` , data);
      action = 'Updated';
    }
    else{
      results = await postCall(CAMPAIGNS_ENDPOINT, data);
      action = 'creada';
    }
    
    if(!results.success){
      alert(results.message);
    }
    else{
      onSuccess(`La campaña ha sido ${action} exitosamente.`);
    }
    console.log(results);
  }

  const onDateChange = (name, data) => {
    setValue (name, data.format());
  }

  return(
    <>
        <Box
          sx={{
            marginTop: 10,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
          }}
        >          
          <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate sx={{ mt: 1 }}>
            <h1>{t("campaigns.associatedPoliticalParty")}</h1>
            
            <Dropdown 
                label={t("campaigns.associatedPoliticalParty")}
                onChange={(x) => setValue("political_party_id", x)} 
                values={parties}  
                defaultValue={getValues('political_party_id')}
            />
            
            <TextField
              margin="normal"
              required
              fullWidth
              id="period"
              label={t("campaigns.period")}
              name="period"
              autoComplete="period"
              autoFocus
              {...register("period")}
            />
            <TextField
              rows={4}
              multiline
              margin="normal"
              required
              fullWidth
              id="description"
              label={t("campaigns.description")}
              name="description"
              autoComplete="description"
              autoFocus
              {...register("description", { required: "Description is required." })}
              error={!!errors.description}
              helperText={errors.description?.message}
            />
            <BasicDatePicker 
              elementId="initial_date" 
              label={t("campaigns.initialDate")}
              onChange={(elementId, newValue) => onDateChange(elementId,newValue)} 
              defaultValue={getValues('initial_date') || dayjs()}
            />
            <BasicDatePicker 
              elementId="execution_date" 
              label={t("campaigns.executionDate")}
              onChange={(elementId, newValue) => onDateChange(elementId,newValue)} 
              defaultValue={getValues('execution_date') || dayjs()}
            />
            <BasicDatePicker 
              elementId="end_date" 
              label={t("campaigns.endDate")}
              onChange={(elementId, newValue) => onDateChange(elementId,newValue)} 
              defaultValue={getValues('end_date') || dayjs()}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="candidate_name"
              label={t("campaigns.candidateName")}
              type="candidate_name"
              id="candidate_name"
              autoComplete="candidate_name"
              {...register("candidate_name")}
            />
            <TextField
              margin="normal"
              required
              fullWidth
              name="voters_goal"
              label={t("campaigns.votersGoal")}
              type="voters_goal"
              id="voters_goal"
              autoComplete="voters_goal"
              {...register("voters_goal")}
            />
            <Checkbox
              color="primary"
              inputProps={{ 'aria-label': 'controlled' }}
              {...register("paid")}
              defaultChecked={getValues('paid')}
            /> {t("campaigns.paid")}
            <TextField
              margin="normal"
              required
              fullWidth
              name="cost"
              label={t("campaigns.cost")}
              type="cost"
              id="cost"
              autoComplete="cost"
              {...register("cost")}
            />
            <Dropdown 
              label={t("common.dropdown.country")}
              values={countries} 
              defaultValue={getValues('country')} 
              onChange={(x) =>  setValue("country", x)} 
            />
            <Dropdown 
              label={t("common.dropdown.province")}
              onChange={(x) =>  setValue("province", x)} 
              values={provinces} 
              defaultValue={getValues('province')} 
            />
            <Dropdown 
              label={t("common.dropdown.canton")}
              onChange={(x) => setValue("canton", x)} 
              values={cantons} 
              defaultValue={getValues('canton')} 
            />
            <Dropdown 
              label={t("common.dropdown.district")}
              onChange={(x) => setValue("district", x)} 
              values={districts} 
              defaultValue={getValues('district')}
            />
            <p>{t("campaigns.imageUpload")}</p>
            <FileUploader 
              onFileSelected={(image) => {
                setImage(image);
              }}
            />
            {image !== null && <img src={image} alt="logo" style={{maxWidth: '100%', maxHeight: '100%'}}/>}
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              {t("common.button.save")}
            </Button>
          </Box>
        </Box>
      </>
  )
};

CreateCampaign.propTypes = {
    onSuccess:PropTypes.func.isRequired,
    editData: PropTypes.object
  };
  
CreateCampaign.defaultProps = {
};

export default CreateCampaign;