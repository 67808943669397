import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
//import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import ChipDropdown from '../common/ChipDropdown';
import { Layout as AdminLayout } from '../../layouts/admin/layout';
import { useForm, Controller } from 'react-hook-form';
import { postCall, putCall } from '../../helpers/apiManager';
import { USERS_ENDPOINT } from '../../routes';
import { useNavigate } from 'react-router-dom';
import { AuthLayout } from '../../layouts/auth/AuthLayout';
import { useEffect, useState } from 'react';
import { CAMPAIGNS_ENDPOINT } from '../../routes';
import { getCall } from '../../helpers/apiManager';
import Roles from '../common/Roles';

import CircularLoader from '../common/CircularLoader';
import AlertTitle from '@mui/material/AlertTitle';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import { isEmpty } from 'lodash';
import PropTypes from 'prop-types';
import { roles as RoleConstants } from '../common/Constants';
import { useTranslation } from 'react-i18next';

const Registration = ({ onSuccess, editData }) => {


  console.log(editData);
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [campaigns, setCampaigns] = useState([]);
  const [selectedCampaigns, setSelectedCampaigns] = useState( editData?.campaigns.map(campaign => campaign.id));
  const [roles, setRoles] = useState( []);
  const [selectedRole, setSelectedRole] = useState(
    !isEmpty(editData?.role_id)
      ? RoleConstants[`${editData?.role_id}`]
      : RoleConstants['3'],
  );
  const [alertMessage, setAlertMessage] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const form = useForm({
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone: '',
      password: '',
      password_confirmation: '',
      country: 'Costa Rica',
      active: editData?.active || true,
      level: 1,
    },
  });

  const { handleSubmit, register, formState, watch, setValue, getValues } =form;

  const { errors } = formState;
  const prepareDropdownData = (values) => {
    const data = values.map((value) => {
      return { value: value.id, label: value.period };
    });
    return data;
  };

  useEffect(() => {
    setRoles([
      { value: 3, label: 'Campaign Admin' },
      { value: 4, label: 'Campaign User' },
      { value: 5, label: 'Call Center User' },
    ]);
  }, []);

  useEffect(() => {
    if (!isEmpty(editData)) {
      Object.keys(editData).forEach((key) => {
        setValue(key, editData[key]);
      });
    }
  }, [editData, setValue]);

  useEffect(() => {
    const fetchCampaigns = async () => {
      const campaignsResults = await getCall(CAMPAIGNS_ENDPOINT);
      if (campaignsResults.success) {
        setCampaigns(prepareDropdownData(campaignsResults.campaigns));
      }
    };
    fetchCampaigns();
  }, []);

  const getIds = (elementsArray, selectedElements) => {
    const ids = selectedElements.map((label) => {
      const index = elementsArray.findIndex(function (element) {
        return element.label === label;
      });
      if (index >= 0) {
        return elementsArray[index].value;
      } else {
        return null;
      }
    });
    return ids;
  };

  const onSubmit = async (data) => {
    setIsLoading(true);
    const campaignIds = getIds(campaigns, selectedCampaigns);
    const roleId = getIds(roles, [selectedRole]);
    let action = '';
    const consolidatedData = {
      user: {
        ...data,
        ...{
          full_name: `${data.first_name} ${data.last_name}`,
          campaigns: campaignIds.filter((x) => x),
        },
      },
    };

    if (roleId !== null && roleId.length > 0) {
      consolidatedData['user']['role_id'] = roleId[0];
    }

    let results = null;
    if (!isEmpty(editData)) {
      results = await putCall(`${USERS_ENDPOINT}/${data.id}`, consolidatedData);
      action = 'actualizado';
    } else {
      results = await postCall(USERS_ENDPOINT, consolidatedData);
      action = 'creado';
    }
    //results = await postCall(USERS_ENDPOINT, consolidatedData, false);
    setIsLoading(false);
    if (!results.success) {
      let errors = '';
      for (let i = 0; i < results.errors.length; i++) {
        errors += results.errors[i] + '\n';
      }
      alert(errors);
    } else {
      const message = !isEmpty(editData)
        ? 'El usuario ha sido actualizado exitosamente'
        : 'El usuario ha sido creado exitosamente, le llegará al correo las ' +
          'instrucciones para activar la cuenta.';

      onSuccess(message);
    }
    console.log(results);
  };

  const renderPasswordFields = () => (
    <>
      <Grid item xs={12}>
        <TextField
          margin="normal"
          required
          fullWidth
          name="password"
          label={t('common.textfield.password')}
          type="password"
          id="password"
          autoComplete="current-password"
          {...register('password', { required: 'Password is required' })}
          error={!!errors.password}
          helperText={errors.password?.message}
        />
      </Grid>
      <Grid item xs={12}>
        <TextField
          margin="normal"
          required
          fullWidth
          name="password_confirmation"
          label={t('common.textfield.passwordConfirm')}
          type="password"
          id="password_confirmation"
          autoComplete="password_confirmation"
          error={!!errors.password_confirmation}
          helperText={errors.password_confirmation?.message}
          {...register('password_confirmation', {
            required: true,
            validate: (val) => {
              if (watch('password') !== val) {
                return t('common.textfield.password.error');
              }
            },
          })}
        />
      </Grid>
    </>
  );

  const renderEditFields = () => {
    return (
      <>
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            margin: 0,
          }}
        >
          <Checkbox
            checked={watch('active')}
            color="primary"
            inputProps={{ 'aria-label': 'controlled' }}
            {...register('active')} // Registers checkbox with form
          />
          Usuario Activo en el Sistema
        </Box>
      </>
    );
  };

  console.log('Edit Data');
  console.log(editData);
  return (
    <>
      <CssBaseline />

      <Box
        sx={{
          marginTop: 10,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <Typography component="h1" variant="h5">
          {t('registration.signUp')}
        </Typography>
        <Box component="form" onSubmit={handleSubmit(onSubmit)} noValidate>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="first_name"
                label={t('common.textfield.firstName')}
                name="first_name"
                autoComplete="first_name"
                autoFocus
                {...register('first_name', {
                  required: 'First name is required.',
                })}
                error={!!errors.first_name}
                helperText={errors.first_name?.message}
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="last_name"
                label={t('common.textfield.lastName')}
                name=""
                autoComplete="last_name"
                autoFocus
                {...register('last_name', {
                  required: 'Last name is required.',
                })}
                error={!!errors.last_name}
                helperText={errors.last_name?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="email"
                label={t('common.textfield.email')}
                name="email"
                autoComplete="email"
                autoFocus
                {...register('email', {
                  required: 'Please enter a valid email.',
                })}
                error={!!errors.email}
                helperText={errors.email?.message}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                margin="normal"
                required
                fullWidth
                id="phone"
                label={t('common.textfield.phone')}
                name="phone"
                autoComplete="phone"
                autoFocus
                {...register('phone', { required: 'Phone number is required' })}
                error={!!errors.phone}
                helperText={errors.phone?.message}
              />
            </Grid>

            {!editData && renderPasswordFields()}
            <Grid item xs={12}>
              <ChipDropdown
                label={t('common.dropdown.campaigns')}
                onChange={(x) => {
                  setSelectedCampaigns(x);
                }}
                values={campaigns}
                isMultiple={true}
                defaultValue={selectedCampaigns}
              />
            </Grid>
            <Grid item xs={12}>
              <ChipDropdown
                label={t('common.dropdown.role')}
                onChange={(x) => {
                  setSelectedRole(x);
                }}
                values={roles}
                defaultValue={
                  !isEmpty(editData?.role_id)
                    ? [RoleConstants[`${editData?.role_id}`]]
                    : [RoleConstants[`${editData?.role_id}`]]
                }
              />
              {editData && renderEditFields()}
            </Grid>
          </Grid>
          {isLoading && <CircularLoader />}
          {!isLoading && (
            <Button
              type="submit"
              fullWidth
              variant="contained"
              sx={{ mt: 3, mb: 2 }}
            >
              { editData ? t('registration.updateButton') : t('registration.signUpButton')}
            </Button>
          )}
        </Box>
      </Box>
    </>
  );
};

Registration.propTypes = {
  onSuccess: PropTypes.func.isRequired,
  editData: PropTypes.object,
};

Registration.defaultProps = {};

export default Registration;
