import * as React from 'react';

import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
//import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import { LOGIN_ENDPOINT } from '../../routes';
import { getCall, postCall } from '../../helpers/apiManager';
import { AuthLayout  } from '../../layouts/auth/AuthLayout';
import { useForm, Controller } from "react-hook-form";
import { useAuth } from '../../hooks/useAuth';
import {  useEffect } from 'react';
import { useNavigate } from "react-router-dom";
import { Layout as AdminLayout } from '../../layouts/admin/layout';
import { CAMPAIGNS_ENDPOINT, POLITICAL_PARTY_ENDPOINT } from '../../routes';
import Dropdown from '../common/Dropdown';
import BasicDatePicker from '../common/BasicDatePicker';
import { PROVINCES_ENDPOINT, 
  CANTONS_ENDPOINT, 
  DISTRICTS_ENDPOINT, 
  COUNTRIES_ENDPOINT 
} from '../../routes';
import { useState } from 'react';
import Edit from '@mui/icons-material/Edit';
import { IconButton } from '@mui/material';
import ModalPopover from '../common/ModalPopover';
import { green } from '@mui/material/colors';
import CommonTable from '../common/CommonTable';
import CreateCampaign from './CreateCampaign';

import AlertTitle from '@mui/material/AlertTitle';
import Alert from '@mui/material/Alert';
import CheckIcon from '@mui/icons-material/Check';
import { isEmpty } from 'lodash';
import dayjs from 'dayjs';
import { useTranslation } from 'react-i18next';

const Campaigns = () =>{
  const { t, i18n } = useTranslation(); 
  const [isPopupOpen, setIsPopupOpen] = useState(false);
  const [headers, setHeaders] = useState([
    "commonTable.header.actions", 
    "commonTable.header.candidate", 
    "commonTable.header.period", 
    "commonTable.header.description", 
    "commonTable.header.end_date"
  ]);
  const [rows, setRows] = useState([]);
  const [editData, setEditData] = useState({});
  const {isLoggedIn, logout} = useAuth();
  const navigate = useNavigate();
  const [alertMessage, setAlertMessage] = useState("");


  const form = useForm({
    defaultValues: {
      period: "",
      country: "",
      province: "",
      canton: "",
      district:"",
      candidate_name:"",
      execution_date:"",
      initial_date:"",
      end_date:"",
      description: "",
      voters_goal:"",
      paid: false,
      cost:0,
      political_party_id: 0
    },
  })

  const { 
    handleSubmit, 
    register,
    formState,
    watch,
    setValue
  } = form;

  const {errors} = formState;

  const prepareDropdownData = (values) => {
    const data = values.map((value) => {
      return { "value" : value , "label": value} 
    });
    return data;
  }
   
  const prepareEditData = async (value) => {
    await setEditData(value);
    console.log("prepareEditData");
    console.log(value);
    await setIsPopupOpen(true);
  }

  const fetchCampaigns = async () => {
    const data = await getCall(CAMPAIGNS_ENDPOINT);
    const campaigns = data.campaigns.map((value) => {
      return { 
        "commonTable.header.candidate" : value.candidate_name , 
        "commonTable.header.period": value.period, 
        "commonTable.header.description": value.description,
        "commonTable.header.end_date": dayjs(value.end_date).format('DD/MM/YYYY'),
        "commonTable.header.actions": (
          <IconButton  
            sx={{border: '1px solid'}} 
            aria-label="edit" 
            color="primary"
            onClick={() => prepareEditData(value)}
          >
            <Edit />
          </IconButton>)
          //<a href="#" onClick={ () => prepareEditData(value)}>Editar</a>
      } 
      
    });
    setRows(campaigns);
  } 

  useEffect( () => {
   fetchCampaigns();
  }, []);

  const onSubmit = async  (data) => { 
    const results = await postCall(CAMPAIGNS_ENDPOINT, data);
    if(!results.success){
      alert(results.message);
    }
    else{
      alert("Campaign Created Successfully");
    }
    console.log(results);
  }

  const onDateChange = (name, data) => {
    setValue (name, data.format());
  }

  return(
    <AdminLayout>
      <Container component="main" >
        <CssBaseline />
        {
        !isEmpty(alertMessage) && <Alert icon={<CheckIcon fontSize="inherit" />} severity="success">
          <AlertTitle>{alertMessage}</AlertTitle>
        </Alert>
        }

        <Grid item xs={12} container alignItems="flex-end" justifyContent="flex-end">
          <Button    
            variant="contained"
            sx={{ mt: 3, mb: 2, alignSelf:'flex-end', justifyContent:'flex-end', alignContent:'flex-end' }}
            color="primary"
            size="large"
            onClick={ async ()=> {
              await setEditData({}); 
              setIsPopupOpen(true)}}
          >
            Agregar
          </Button>
        </Grid>
        <CommonTable 
          headers={headers} 
          rows={rows} 
        />
        <ModalPopover isOpen={isPopupOpen} onCloseFunction={() => setIsPopupOpen(false)} >
          <CreateCampaign 
            onSuccess={(text)=> {
              setAlertMessage(text);
              fetchCampaigns();
              setIsPopupOpen(false); 
              setTimeout(() => {
                setAlertMessage("");
              }, 20000)
            }}
            editData={editData}
          />
        </ModalPopover>
      </Container>
    </AdminLayout>
  )
};

export default Campaigns;