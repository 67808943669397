import PropTypes from 'prop-types';
import React from 'react';
import { useTranslation } from 'react-i18next';
import Dropdown from './Dropdown';
import { setLanguage, getLanguage } from '../../config/i18n';
import { useEffect, useState } from 'react';

const LanguageSelector = ({ size, level }) => {
  const { t } = useTranslation(); 
  const [language, setLanguage] = useState();


  useEffect(() => {
    const fetchLanguage = async () => {
      const lang = await getLanguage();
      await setLanguage(lang);
    }
    fetchLanguage();
  }, []);

  return null;
  return(
    <>
      <Dropdown 
        defaultValue={language}
        label="Idioma" 
        onChange={(x) =>  setLanguage(x)} 
        values={[
          { "value" : 'es' , "label": 'Español'} ,
          { "value" : 'en' , "label": 'Inglés'} 
        ]}  
      />
    </>
  )
};

LanguageSelector.propTypes = {

};

LanguageSelector.defaultProps = {

};

export default LanguageSelector;
